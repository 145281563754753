// // /* eslint-disable react-hooks/exhaustive-deps */
// // import React, { useEffect, useState } from 'react';
// // import DataTableLimit from '../Datatable/DataTableLimit';
// // import { Grid, Chip } from '@mui/material';
// // import { useDispatch, useSelector } from 'react-redux';
// // import { getAllDealsPanelData } from '../../redux/features/dealsSlice';
// // import PIDGraph from './PIDGraph';


// // function LiveDeals() {
// //     const dispatch = useDispatch();
// //     const { allDealsPanelData, limit } = useSelector((state) => ({ ...state.deals }));

// //     console.log(limit, "limit")


// //     // Table head data
// //     const columns = [
// //         {
// //             name: 'store_name',
// //             label: 'Store Name',
// //             options: {
// //                 filter: true,
// //                 sort: true,
// //             }
// //         },
// //         {
// //             name: 'link',
// //             label: 'Store Product Link',
// //             options: {
// //                 customBodyRender: (link) => {
// //                     return (
// //                         <a href={link} target="_blank" rel="noreferrer">{link}</a>
// //                     )
// //                 },
// //                 filter: true,
// //                 sort: true,
// //             }
// //         },
// //         {
// //             name: 'PID',
// //             label: 'PID',
// //             options: {
// //                 customBodyRender: (pid) => {
// //                     return (
// //                         <PIDGraph
// //                             allDealsPanelData={allDealsPanelData}
// //                             pid={pid}
// //                         />
// //                     )
// //                 },
// //             }
// //         },
// //         {
// //             name: 'price',
// //             label: 'Price',
// //             options: {
// //                 filter: true,
// //                 sort: true,
// //             }
// //         },
// //         {
// //             name: 'old_price',
// //             label: 'Old Price',
// //             options: {
// //                 filter: true,
// //                 sort: true,
// //             }
// //         },
// //         {
// //             name: 'discount',
// //             label: 'Discount',
// //             options: {
// //                 filter: true,
// //                 sort: true,
// //             }
// //         },
// //         {
// //             name: 'inStock',
// //             label: 'In Stock',
// //             options: {
// //                 filter: true,
// //                 sort: true,
// //             }
// //         },
// //         {
// //             name: 'old_price_time',
// //             label: 'Old Price Time',
// //             options: {
// //                 filter: true,
// //                 sort: true,
// //             }
// //         },
// //         {
// //             name: 'inactive',
// //             label: 'Is Active',
// //             options: {
// //                 customBodyRender: (row) => {
// //                     if (row === 0) {
// //                         return (
// //                             <Grid container justifyContent="center">
// //                                 <Chip label="Active" color="success" variant="outlined" />
// //                             </Grid>
// //                         )
// //                     }
// //                     else {
// //                         return (
// //                             <Grid container justifyContent="center">
// //                                 <Chip label="Inactive" color="error" variant="outlined" />
// //                             </Grid>
// //                         )
// //                     }
// //                 },
// //                 filter: true,
// //                 sort: true,
// //             }
// //         },
// //         {
// //             name: 'time',
// //             label: 'Time',
// //             options: {
// //                 filter: true,
// //                 sort: true,
// //             }
// //         },
// //     ]


// //     useEffect(() => {
// //         let data = {};
// //         data.limit = limit;
// //         dispatch(getAllDealsPanelData(data));
// //     }, [limit]);
// //     // console.log(allDealsPanelData, "allDealsPanelData");


// //     return (
// //         <DataTableLimit name="Live Deals" columns={columns} data={allDealsPanelData && allDealsPanelData} />
// //     )
// // }

// // export default LiveDeals



// import { useState, useEffect } from 'react';
// import axios from 'axios';
// import { Grid, Chip, Button } from '@mui/material';
// import {
//     Paper,
//     Table,
//     TableHead,
//     TableBody,
//     TableRow,
//     TableCell,
//     TablePagination
// } from '@mui/material';
// import RuleRoundedIcon from '@mui/icons-material/RuleRounded';
// import { NavLink } from 'react-router-dom'
// // import { getAllDealsPanelData } from '../../redux/features/dealsSlice';
// import AppConstants from '../../constants/AppConstants';
// import PIDGraph from './PIDGraph';


// const LiveDeals = () => {
//     const [allDealsPanelData, setallDealsPanelData] = useState([]);
//     const [passengersCount, setPassengersCount] = useState(0);
//     const [controller, setController] = useState({
//         page: 0,
//         rowsPerPage: 10
//     });


//     const handlePageChange = (event, newPage) => {
//         setController({
//             ...controller,
//             page: newPage
//         });
//     };

//     const handleChangeRowsPerPage = (event) => {
//         setController({
//             ...controller,
//             rowsPerPage: parseInt(event.target.value, 10),
//             page: 0
//         });
//     };

//     useEffect(() => {
//         let data = {};
//         data.limit = controller.rowsPerPage;
//         data.page = controller.page;
//         console.log(data, "1")
//         const getData = async () => {
//             const url = `${AppConstants.baseURL}/deals/livedeals`
//             try {
//                 console.log(data, "2")
//                 const response = await axios.post(url, data);
//                 if (response.statusText === 'OK') {
//                     const data = await response.data;
//                     // console.log(data);
//                     setallDealsPanelData(data.data);
//                     setPassengersCount(data.totalCounts[0].total_deals);
//                 } else {
//                     throw new Error('Request failed')
//                 }
//             } catch (error) {
//                 console.log(error);
//             }
//         };
//         getData();
//         // dispatch(getAllDealsPanelData(data));
//     }, [controller]);
//     // console.log(allDealsPanelData, "allDealsPanelData")
//     return (
//         <>
//             <NavLink to="/livedeals-processed">
//                 <Button
//                     variant="contained"
//                     color="success"
//                     startIcon={<RuleRoundedIcon />}
//                     sx={{ marginBottom: 2 }}
//                 >
//                     <strong>Process Live Deals</strong>
//                 </Button>
//             </NavLink>
//             <Paper elevation={5}>
//                 <Table>
//                     <TableHead>
//                         <TableRow>
//                             <TableCell>   Store_name   </TableCell>
//                             <TableCell>   Link </TableCell>
//                             <TableCell> PID</TableCell>
//                             <TableCell>  Price  </TableCell>
//                             <TableCell>    Old_price  </TableCell>
//                             <TableCell>    Discount </TableCell>
//                             <TableCell>   InStock</TableCell>
//                             <TableCell> Old Price Time  </TableCell>
//                             <TableCell> Inactive </TableCell>
//                             <TableCell>  Time   </TableCell>
//                         </TableRow>
//                     </TableHead>
//                     <TableBody>
//                         {allDealsPanelData.map((passenger) => (
//                             <TableRow key={passenger._id}>
//                                 <TableCell>  {passenger.store_name} </TableCell>
//                                 <TableCell><a href={passenger.link} target="_blank" rel="noreferrer">{passenger.link}</a> </TableCell>
//                                 <TableCell>
//                                     <PIDGraph
//                                         allDealsPanelData={allDealsPanelData}
//                                         pid={passenger.PID}
//                                     />
//                                 </TableCell>
//                                 <TableCell>
//                                     {passenger.price}
//                                 </TableCell>
//                                 <TableCell>
//                                     {passenger.old_price}
//                                 </TableCell>
//                                 <TableCell>
//                                     {passenger.discount}
//                                 </TableCell>
//                                 <TableCell>
//                                     {passenger.inStock}
//                                 </TableCell>
//                                 <TableCell>
//                                     {passenger.old_price_time}
//                                 </TableCell>
//                                 <TableCell>
//                                     {passenger.inactive == 1 ?
//                                         <Grid container justifyContent="center">
//                                             <Chip label="Active" color="success" variant="outlined" />
//                                         </Grid>
//                                         :
//                                         <Grid container justifyContent="center">
//                                             <Chip label="Inactive" color="error" variant="outlined" />
//                                         </Grid>
//                                     }
//                                 </TableCell>
//                                 <TableCell>
//                                     {passenger.time}
//                                 </TableCell>
//                             </TableRow>
//                         ))}
//                     </TableBody>
//                 </Table>
//                 <TablePagination
//                     component="div"
//                     onPageChange={handlePageChange}
//                     page={controller.page}
//                     count={passengersCount}
//                     rowsPerPage={controller.rowsPerPage}
//                     onRowsPerPageChange={handleChangeRowsPerPage}
//                 />
//             </Paper>
//         </>
//     )
// }

// export default LiveDeals;






/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { Button, LinearProgress } from '@mui/material'
import { RuleRounded } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { deactiveDealsData, getAllDealsPanelData } from '../../redux/features/dealsSlice'
import Datatable from '../Datatable/Datatable'

function LiveDeals() {
    const dispatch = useDispatch()
    const { allDealsPanelData, loading } = useSelector(state => state.deals)

    // handlers
    const DeactivateDeal = (data) => {

        dispatch(deactiveDealsData(data))
        dispatch(getAllDealsPanelData())
    }

    const columns = [
        {
            name: 'id',
            label: 'Action',
            options: {
                customBodyRender: (id, tableMeta) => {
                    let data = {}
                    data.id = id
                    data.Store = tableMeta.rowData[1]
                    data.PID = tableMeta.rowData[2]
                    return (
                        <Button color="error" size="small" onClick={() => DeactivateDeal(data)}>
                            <strong>Deactivate</strong>
                        </Button>

                    )
                },
                filter: true,
                sort: true,
            }
        },
        {
            name: 'Store',
            label: 'Store',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'PID',
            label: 'PID',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'link',
            label: 'Link',
            options: {
                customBodyRender: (link) => {
                    return <a href={link} target="_blank" rel="noreferrer">{link}</a>
                },
                filter: true,
                sort: true,
            }
        },
        {
            name: 'price',
            label: 'Price',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'min',
            label: 'Min',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'mean',
            label: 'Mean',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'count',
            label: 'Count',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'median',
            label: 'Median',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'drop_median',
            label: 'Drop Median',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'p20',
            label: 'p20',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'drop_p20',
            label: 'Drop p20',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'day_percent_30',
            label: 'Percent Day 30',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'day_percent_90',
            label: 'Percent Day 90',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'inStock',
            label: 'Stock',
            options: {
                customBodyRender: (stock) => {
                    if (stock === 1) return "In stock";
                    else return "Out of Stock";
                },
                filter: true,
                sort: true,
            }
        },
        {
            name: 'time',
            label: 'Date & Time',
            options: {
                filter: true,
                sort: true,
            }
        },
    ]

    useEffect(() => {
        dispatch(getAllDealsPanelData())
    }, [])
    return (
        <>
            <NavLink to="/livedeals-processed">
                <Button
                    variant="contained"
                    color="success"
                    startIcon={<RuleRounded />}
                    sx={{ marginBottom: 2 }}
                >
                    <strong>Approve Live Deals</strong>
                </Button>
            </NavLink>
            {loading && <LinearProgress />}
            <Datatable name="Live Deals" columns={columns} data={allDealsPanelData && allDealsPanelData} />
        </>
    )
}

export default LiveDeals;